/* NanumSquare */
@font-face {
    font-family: 'NanumSquare';
    font-weight: 400;
    src: url(font/NanumSquareR.eot);
    src: url(font/NanumSquareR.eot?#iefix) format('embedded-opentype'),
         url(font/NanumSquareR.woff) format('woff'),
         url(font/NanumSquareR.ttf) format('truetype');
   }
   @font-face {
    font-family: 'NanumSquare';
    font-weight: 700;
    src: url(font/NanumSquareB.eot);
    src: url(font/NanumSquareB.eot?#iefix) format('embedded-opentype'),
         url(font/NanumSquareB.woff) format('woff'),
         url(font/NanumSquareB.ttf) format('truetype');
   }
   @font-face {
    font-family: 'NanumSquare';
    font-weight: 800;
    src: url(font/NanumSquareEB.eot);
    src: url(font/NanumSquareEB.eot?#iefix) format('embedded-opentype'),
         url(font/NanumSquareEB.woff) format('woff'),
         url(font/NanumSquareEB.ttf) format('truetype');
   }
   @font-face {
    font-family: 'NanumSquare';
    font-weight: 300;
    src: url(font/NanumSquareL.eot);
    src: url(font/NanumSquareL.eot?#iefix) format('embedded-opentype'),
         url(font/NanumSquareL.woff) format('woff'),
         url(font/NanumSquareL.ttf) format('truetype');
   }

/* Nanum Barun Gothic  */ 
@font-face {
      font-family: 'Nanum Barun Gothic';
      font-style: normal;
      font-weight: 200;
      src: local('Nanum Barun Gothic UltraLight'), local('Nanum Barun Gothic-UltraLight'), local('NanumBarunGothic UltraLight');
      src: url(font/NanumBarunGothicUltraLight.eot);
      src: url(font/NanumBarunGothicUltraLight.eot?#iefix) format('embedded-opentype'),
           url(font/NanumBarunGothicUltraLight.woff) format('woff'),
           url(font/NanumBarunGothicUltraLight.ttf) format('truetype');
    }
    
    @font-face {
      font-family: 'Nanum Barun Gothic';
      font-style: normal;
      font-weight: 300;
      src: local('Nanum Barun Gothic Light'), local('Nanum Barun Gothic-Light'), local('NanumBarunGothic Light');
      src: url(font/NanumBarunGothicLight.eot);
      src: url(font/NanumBarunGothicLight.eot?#iefix) format('embedded-opentype'),
           url(font/NanumBarunGothicLight.woff) format('woff'),
           url(font/NanumBarunGothicLight.ttf) format('truetype');
    }
    
    @font-face {
      font-family: 'Nanum Barun Gothic';
      font-style: normal;
      font-weight: 400;
      src: local('Nanum Barun Gothic Regular'), local('Nanum Barun Gothic-Regular'), local('NanumBarunGothic Regular');
      src: url(font/NanumBarunGothic.eot);
      src: url(font/NanumBarunGothic.eot?#iefix) format('embedded-opentype'),
           url(font/NanumBarunGothic.woff) format('woff'),
           url(font/NanumBarunGothic.ttf) format('truetype');
    }
    
    @font-face {
      font-family: 'Nanum Barun Gothic';
      font-style: normal;
      font-weight: 700;
      src: local('Nanum Barun Gothic Bold'), local('Nanum Barun Gothic-Bold'), local('NanumBarunGothic Bold');
      src: url(font/NanumBarunGothicBold.eot);
      src: url(font/NanumBarunGothicBold.eot?#iefix) format('embedded-opentype'),
           url(font/NanumBarunGothicBold.woff) format('woff'),
           url(font/NanumBarunGothicBold.ttf) format('truetype');
    }