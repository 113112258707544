* {box-sizing: border-box;margin:0;padding:0;}

:root {
  --font-size: 12px!important;
}
#root { position: absolute; top: 0; bottom: 0; left: 0; right: 0; min-width: 1400px; } over html, body { height: 100% }?

html, body {width: 100%; height: 100%; margin: 0; padding:0; border: 0; outline: 0;}
body {
  margin: 0;
  font-family: AppleSDGothicNeo-Regular, 'Nanum Barun Gothic', 'Malgun Gothic', '맑은 고딕', dotum, '돋움', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  line-height: 1.6
}

* {font-family: AppleSDGothicNeo-Regular, 'Nanum Barun Gothic', 'Malgun Gothic', '맑은 고딕', dotum, '돋움', sans-serif;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul, li {list-style: none;}
ul::after {display: block; content: ''; clear: both;}

table{width:100%;table-layout: fixed;}

.clearfix::after {display: block; content: ''; clear: both;}

a {text-decoration: none;}