.button-control-box {
  display: flex;
  flex-wrap: column;
  height: 100%;
  margin: 0;
  padding: 0;
  height: 28px;
}
.button-control {
  overflow: auto;
  margin: 0 2px 0 2px;
  padding: 0;
}
.button-control-item {
  line-height: 28px;
  height: 28px;
  padding: 0;
  margin: 0 auto;
  border: 0;
}

.resizable {
  position: relative;
}
.resizable .react-resizable-handle {
  position: absolute;
  width: 20;
  height: 20;
  bottom: 0;
  right: 0;
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}
.dialog-content-body {
  height: -webkit-calc(100% - 126px);
  height: -moz-calc(100% - 126px);
  height: calc(100% - 126px);
}
.dialog-nobutton-content-body {
  height: -webkit-calc(100% - 64px);
  height: -moz-calc(100% - 64px);
  height: calc(100% - 64px);
}

/* 조회 */
.input_search {
  position: relative;
  padding: 0 90px 8px 15px;
  background: var(--search-bg);
  border-bottom: 1px solid var(--search-border-color);
}
.MuiPaper-root .input_search {
  padding: 10px 100px 10px 20px;
}
.MuiPaper-root .input_search .btn_wrap {
  top: 12px;
  right: 15px;
  width: auto;
}
.MuiPaper-root .input_search .btn_wrap button {
  width: auto;
  height: 30px;
  background-color: var(--search-button-bg);
  border: 1px solid var(--search-button-border-color);
  border-radius: 4px;
}
.MuiPaper-root .input_search .btn_wrap button:hover {
  background-color: var(--search-button-border-color);
}
.input_search th {
  text-align: right;
  width: 10%;
  padding: 3px 10px 3px 0;
  vertical-align: top;
}
.input_search td {
  text-align: left;
  padding: 2px 15px 2px 0;
  width: 15%;
  height: 33px;
  vertical-align: top;
}
.main-body-search-box .btn_wrap,
.MuiDialog-root .main-body-search-box .btn_wrap {
  position: absolute;
  top: 3px;
  right: 0;
}

/* 입력 */
.input_record {
  padding: 0 0 10px 0;
}
.input_record th {
  text-align: right;
  width: 10%;
  padding: 4px 10px 10px 0;
  vertical-align: top;
}
.input_record td {
  text-align: left;
  padding: 2px 15px 2px 0;
  width: 15%;
  vertical-align: top;
}

/* 결과 */
.splitter-panel {
  border: 0px solid #ccc !important;
}

/* label */
label {
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: #333;
  font-weight: normal;
  padding-top: 2px;
  padding-left: 9px;
  line-height: 1.6;
}
th > legend {
  display: inline-block;
  position: relative;
  font-family: inherit !important;
  font-size: 14px !important;
  color: #333;
  font-weight: normal !important;
  padding-left: 18px !important;
  line-height: 1.6 !important;
}
label > span {
  display: inline-block;
  position: relative;
  font-family: inherit !important;
  font-size: 14px !important;
  color: #333 !important;
  line-height: 1.6 !important;
}
.input_record label {
  /* color: #999; */
  color: var(--label-required-font-color);
}
.input_record label.required {
  color: var(--label-required-font-color);
}
.input_search th label::before,
.input_search th legend::before {
  display: block;
  content: '';
  position: absolute;
  top: 11px;
  left: 0;
  width: 4px;
  height: 4px;
  background-color: #ed1c24;
}
.input_record th label::before,
.input_record th legend::before {
  display: block;
  content: '';
  position: absolute;
  top: 11px;
  left: 0;
  width: 4px;
  height: 4px;
  background-color: #aaa;
}
.input_record label.required::before,
.input_search th legend.required::before {
  background-color: #de071a;
}

/* input */
.MuiInputBase-input {
  display: inline-block;
  height: 32px !important;
  padding: 2px 8px !important;
  background: #fff !important;
  border: 1px solid #ccc !important;
  border-radius: 3px;
  outline: 0;
  font-size: 14px !important;
  color: #666 !important;
  box-sizing: border-box !important;
}
.input_search .MuiFormControl-root,
.input_record .MuiFormControl-root {
  width: 100%;
  /* height: 30px; */
}
.search-control-textfield {
  width: calc(100% - 64px) !important;
  height: 30px;
}
.search-control-button {
  height: 30px;
  /* margin-top: -4px !important; */
}
.input_search .MuiInputBase-input {
  background-color: #fff;
  padding: 2px 8px !important;
  transition: all 200ms;
}
.input_search .MuiInputBase-input:focus {
  border: 1px solid #666;
}
.input_record .MuiInputBase-input {
  background-color: #fff;
  padding: 2px 8px !important;
  transition: all 200ms;
}
.input_search .MuiInputBase-input:focus {
  border: 1px solid #666;
}
.MuiInput-underline:before,
.MuiInput-underline:after {
  display: none;
}

.MuiInputBase-inputMultiline {
  height: auto !important;
}

/* select */
.css-yk16xz-control,
.css-1pathdxg-control,
.select__control--is-focused {
  min-height: 30px !important;
  border-color: #ccc !important;
}
.css-yk16xz-control:hover,
.css-1pathdxg-control:hover,
.select__control--is-focused {
  border-color: #999 !important;
}
.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 4px !important;
}
.iso-e3-select > div {
  /* height: 32px; */
  min-height: auto;
  border-radius: 3px;
  box-shadow: unset;
}
.iso-e3-select .select__indicators {
  margin: 0;
}

.select__menu {
  /* .select__menu.css-cpvz9k-menu { */
  z-index: 9999 !important;
  display: block !important;
  background: #fff !important;
  border: 1px solid #ccc;
  margin-top: -1px !important;
  padding: 0 !important;
  border-radius: 3px;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.1);
}
.select__single-value {
  top: 40% !important;
}
.select__indicator-separator {
  width: 0 !important;
}
.select__menu {
  bottom: 1px !important;
  margin: 0 !important;
}
.select__menu > div {
  padding: 0 !important;
  background-color: #fff;
  border: 1px solid #ccc;
}
.select__menu > div > * {
  padding: 6px 10px;
}
.select__menu > div > *:hover {
  background: #f0f0f1;
  color: #333;
}

/* datetime */
.jqx-datetimeinput {
  height: 30px !important;
}
.jqx-datetimeinput .jqx-input-content {
  padding: 7px 5px;
}
.jqx-datetimeinput .jqx-fill-state-normal {
  background-color: #eaebef;
}
.jqx-icon-calendar,
.jqx-icon-calendar-hover,
.jqx-icon-calendar-pressed {
  background: url(/src/images/calendar.png) no-repeat center;
}

.calendar-header .jqx-calendar-column-cell {
  font-size: 11px;
  color: #999;
}

.jqx-calendar {
  border-color: #ccc;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.1);
}
.jqx-calendar .jqx-calendar-title-container td {
  border-bottom: 1px solid #ccc;
}
.jqx-calendar-column-header {
  border-bottom: 0;
}
.jqx-calendar-title-content {
  font-size: 14px;
}
.jqx-calendar-cell.jqx-fill-state-hover,
.jqx-calendar-cell.jqx-fill-state-selected {
  background-color: #e5e5e5;
  border-color: #ccc;
}
.jqx-calendar-cell.jqx-fill-state-today,
.jqx-calendar-cell-today {
  background-color: #878787;
  border-color: #737373;
  color: #fff !important;
}
.jqx-calendar-cell.jqx-fill-state-pressed {
  background-color: #de071a;
  border-color: #de071a;
  color: #fff;
}

.react-datepicker-popper {
  z-index: 100 !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-wrapper .react-datepicker__input-container > input,
.input_calendar {
  width: 100%;
  min-height: 30px;
  background: #fff url(/src/images/calendar.png) no-repeat 10px 50%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 10px 6px 30px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  color: #666;
  outline: 0;
}
.react-datepicker-wrapper .react-datepicker__input-container > input.focus_visible {
  border: 1px solid #999;
}
.react-datepicker {
  border: 1px solid #ccc !important;
  box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.1);
}
.react-datepicker__navigation {
  outline: 0;
}
.react-datepicker__day-name {
  font-size: 13px !important;
  color: #888 !important;
}
.react-datepicker__day {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  height: 1.7rem;
  line-height: 1.65rem;
  margin: 1px 3px !important;
  border: 1px solid transparent;
  border-radius: 50px !important;
  outline: 0;
}
.react-datepicker__day:hover {
  background-color: #e5e5e5 !important;
  border-color: #ccc;
}
.react-datepicker__day--today {
  background-color: #878787 !important;
  border-color: #737373;
  color: #fff !important;
  font-weight: 400 !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: #de071a !important;
  border-color: #de071a;
  color: #fff;
  font-weight: 400;
}

/* radio */
.MuiRadio-root .MuiSvgIcon-root {
  width: 14px;
  height: 14px;
  color: #fff;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 30px;
}
.MuiRadio-root.Mui-checked .MuiSvgIcon-root {
  color: #fff;
  border-color: var(--search-button-border-color);
  background: #fff url(/src/images/check.png) no-repeat 45% 50%;
}
.MuiRadio-root .MuiSvgIcon-root path {
  display: none;
}

/* check - 원 */
.PrivateSwitchBase-root-1 {
  padding: 5px !important;
}
.MuiCheckbox-root .MuiSvgIcon-root {
  width: 18px;
  height: 18px;
  color: #fff;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 30px;
}
.menu_hide > .side-menu-auto-hidden > span {
  padding: 5px 3px !important;
}
.MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
  color: #fff;
  background: #fff url(/src/images/check.png) no-repeat 45% 50%;
  border-color: var(--search-button-border-color);
}
.MuiCheckbox-root .MuiSvgIcon-root path {
  display: none;
}

/* check- 네모 */
.jqx-checkbox-default {
  background-color: unset !important;
  border: 0 !important;
}
.jqx-checkbox-default > div > span {
  display: block;
  width: 18px !important;
  height: 18px !important;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.jqx-checkbox-default > div > span.jqx-checkbox-check-checked,
.jqx-checkbox-default > div > span.jqx-checkbox-check-indeterminate {
  background: #fff url(/src/images/check.png) no-repeat center;
  border-color: #de071a;
}

/* file */
.filebtnForm input[type='file'] {
  display: none;
}
.filebtnForm .btn_file {
  height: 30px;
  padding: 0 8px;
  background-color: #eaebef;
  border: 1px solid #ccc;
  color: #333;
  border-radius: 4px;
  margin-right: 3px;
  box-shadow: none;
  outline: 0;
}
.filebtnForm .btn_file:hover {
  color: #333;
  border-color: #999;
}

/* grid */
.grid {
  padding: 10px 15px !important;
}
.jqx-grid {
  width: 100%;
  height: 100%;
}
.jqx-widget-header {
  background-color: #f0f1f2;
  font-size: 14px;
  color: #333;
  font-weight: 600;
  height: 23px;
}
.jqx-widget .jqx-grid-cell,
.jqx-widget .jqx-grid-group-cell {
  border-bottom: var(--result-border-h);
  border-right: var(--result-border-v);
}
.jqx-widget .jqx-grid-column-header {
  /*border-bottom: var(--result-border-h);*/
  border-right: var(--result-border-v);
}

.jqx-widget-header > div,
.jqx-widget-header > div > div,
.jqx-grid-column-header > div > div {
  line-height: 23px;
}
.jqx-widget-header.jqx-tabs-headerWrapper {
  background-color: #f1f2f5;
  height: 28px !important;
}

.up .jqx-widget-header {
  background-color: #fff3d9;
}

.up .jqx-overflow-hidden {
  border-radius: 4px;
}

.jqx-tabs {
  border: 0;
  border-radius: 0;
}

.jqx-tabs-titleContentWrapper {
  margin-top: 0 !important;
}
.jqx-tabs-title-selected-top {
  background: #fff5f5;
  border-bottom: 1px solid #fff5f5;
}
.jqx-tabs-title-selected-top .jqx-disableselect {
  color: #de071a;
}

.jqx-grid-statusbar {
  height: 25px !important;
}
.grid-statusbar-total {
  line-height: 25px;
  padding-left: 10px;
}
.grid-statusbar-total-value {
  color: #de071a;
}

.jqx-grid .jqx-grid-cell-sort {
  background-color: #eaebef;
}

.jqx-grid .jqx-grid-cell-left-align {
  padding: 0 5px;
}
.jqx-grid .jqx-fill-state-pressed {
  position: relative;
  background-color: #fce6e8;
  /*box-shadow: 0 2px 5px rgb(0 0 0 / 20%);*/
}
.jqx-grid .jqx-fill-state-pressed:first-child::before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: var(--search-button-bg);
}

.jqx-grid .jqx-grid-cell-pinned {
  background-color: var(--result-cell-pinned-bg);
  color: var(--result-cell-pinned-font-color);
  /*box-shadow: 2px 0 5px rgb(0 0 0 / 20%);*/
}
.table_org .jqx-grid-cell-pinned {
  background-color: #ffedd4;
  color: #ff9900;
}
.table_grn .jqx-grid-cell-pinned {
  background-color: #c3f0eb;
  color: #2cab9f;
}
.table_red .jqx-grid-cell-pinned {
  background-color: #fff5f5;
  color: #de071a;
}

.jqx-grid .jqx-fill-state-hover {
  background-color: #f1f2f5 !important;
}

.table_org .jqx-widget-header {
  background-color: #fff8ed;
}
.table_grn .jqx-widget-header {
  background-color: #e5f9f7;
}
.table_red .jqx-widget-header {
  background-color: #fff5f5;
}

/* tree */
.jqx-tree .jqx-fill-state-hover {
  background-color: #e5e5e5;
  border-color: #ccc;
}
.jqx-tree .jqx-fill-state-pressed {
  background-color: #878787;
  border-color: #737373;
  color: #fff;
}

/* button */

.btn_vertical_wrap {
  text-align: right;
  /* width: 100%; */
}
.btn_vertical_wrap button {
  margin-bottom: 4px;
  width: 100%;
}

.btn_wrap {
  text-align: right;
  padding: 0 15px 0 0;
}
.btn_wrap button,
.btn_vertical_wrap button {
  display: inline-block;
  min-width: 62px !important;
  padding: 0 12px;
  background-color: var(--default-button-bg);
  border: 1px solid var(--default-button-border-color);
  font-size: 14px;
  color: var(--default-button-font-color);
  font-weight: 600;
  border: 0;
  border-radius: 3px;
  margin-left: 4px;
  outline: 0;
  line-height: 30px;
  box-shadow: none !important;
}
.btn_wrap button:hover,
.btn_vertical_wrap button:hover {
  background-color: var(--default-button-border-color);
}
.btn_default {
  display: inline-block !important;
  padding: 3px 17px !important;
  background-color: #757575 !important;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: 0 !important;
  margin-left: 4px !important;
  outline: 0 !important;
}
.btn_default:hover {
  background-color: #555 !important;
}

.MuiPaper-root .input_search .btn_wrap.org button,
.MuiPaper-root .input_search .btn_wrap.org button:hover {
  background-color: #ff9900;
}

.main-body-search-area {
  /*background: var(--search-bg);*/
}
.main-body-search-area .btn_wrap button:first-child {
  background: var(--search-button-bg) url('../images/btn_search.png') no-repeat 9px 43%;
  color: var(--search-button-font-color);
  border: 1px solid var(--search-button-border-color);
  padding-left: 27px;
}
.main-body-search-area .btn_wrap button:first-child:hover {
  background-color: var(--search-button-border-color);
}
.btn_wrap button:first-child {
  margin-left: 0;
}

.MuiButton-containedPrimary {
  background-color: var(--search-button-bg) !important;
  border: 1px solid var(--search-border-color) !important;
  color: #fff !important;
}
.MuiButton-containedSecondary {
  background-color: #2cab9f !important;
  color: #fff !important;
}

.btn_group {
  background-color: #fff5f5;
  padding: 5px 15px !important;
  border-bottom: 1px solid #ddd;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}

.btn_group .MuiButton-root {
  color: #fff;
  margin-left: 3px;
  margin-bottom: 3px;
  box-shadow: none;
}
.btn_group .MuiButton-root,
.btn_group .MuiButton-root:hover {
  background-color: #ff9900;
}
.btn_group.blue .MuiButton-root,
.btn_group.blue .MuiButton-root:hover {
  background-color: #267bcd;
}
.btn_group.green .MuiButton-root,
.btn_group.green .MuiButton-root:hover {
  background-color: #2cab9f;
}
.btn_group.gray .MuiButton-root,
.btn_group.gray .MuiButton-root:hover {
  background-color: #999;
  border-color: #999;
}

.grid-cell-button,
input[type='button'].jqx-fill-state-normal,
.jqx-grid-cell button {
  display: inline-block;
  width: auto !important;
  height: auto !important;
  padding: 2px 5px !important;
  background-color: #e5f9f7;
  font-size: 12px;
  color: #2cab9f;
  font-weight: 400;
  border: 1px solid #e5f9f7;
  border-radius: 4px;
  margin: 0 2px !important;
  outline: 0;
}
.grid-cell-button:hover,
input[type='button'].jqx-fill-state-hover,
input[type='button'].jqx-fill-state-pressed,
.jqx-grid-cell button:hover {
  display: inline-block;
  width: auto !important;
  height: auto !important;
  padding: 2px 5px !important;
  background-color: #fff !important;
  font-size: 12px;
  color: #2cab9f;
  font-weight: 400;
  border-color: #2cab9f;
  border-radius: 4px;
  margin: 0 2px !important;
  outline: 0;
}

/* modal */
.MuiDialog-root .MuiDialogTitle-root {
  background-color: var(--dialog-header-bg);
  border-bottom: 1px solid var(--dialog-header-border-color);
  padding: 12px 20px 10px;
}
/*
.MuiDialogContent-root .MuiTypography-root{
  padding:5px 20px 50px;
}
.MuiDialogContent-root.alert .MuiTypography-root{
  padding:0;
}
*/
.MuiDialog-root .MuiTypography-h6 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
}
.MuiDialog-root .MuiDialogTitle-root .MuiButtonBase-root {
  top: 1px !important;
}
.MuiDialog-root .MuiDialogContent-root.alert {
  width: 360px;
  padding: 15px 15px 52px;
}

.iso-modal-alert .MuiPaper-root {
  min-width: 360px;
  min-height: 150px;
}
.iso-modal-alert .MuiDialogTitle-root {
  padding: 10px 20px 5px;
}
.iso-modal-alert .MuiDialogContentText-root {
  padding: 8px 20px 45px;
}
.MuiDialog-root.iso-modal-alert .input_search {
  margin: -10px -20px 10px;
}
.MuiDialog-root.iso-modal-alert .main-body-search-area .btn_wrap {
  top: 11px;
  left: auto;
  right: 15px;
}

.MuiDialog-root .MuiInputAdornment-positionEnd {
  margin: 0;
}

.MuiDialog-root .MuiFormControl-root {
  width: 100%;
  /*margin-bottom:7px;*/
}
.MuiDialog-root .MuiInputBase-input {
  padding: 2px 8px !important;
  background-color: #fff;
}
.MuiDialog-root p {
  color: #999;
}

.MuiDialog-root .btn_wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0;
  margin: 0;
}

.MuiDialog-root .btn_wrap button,
.MuiDialogActions-root button {
  width: 100%;
  height: 38px;
  background-color: #666;
  margin: 0;
  border: 0;
  border-radius: 0;
  font-size: 14.5px;
  color: #fff;
  font-weight: 600;
}
.MuiDialog-root .btn_wrap button:hover,
.MuiDialogActions-root button:hover {
  background-color: #444;
  border: 0;
  color: #fff;
}
.MuiDialog-root .btn_wrap button.MuiButton-containedPrimary {
  background-color: #de071a;
}
.MuiDialog-root .btn_wrap button .MuiSvgIcon-root {
  display: none;
}

.MuiDialog-root .main-body-search-area .btn_wrap {
  top: 0;
  left: auto;
  width: auto;
}
.MuiDialog-root .main-body-search-area .btn_wrap button {
  width: auto;
  height: auto;
  border-radius: 4px;
}

.cell_padding .jqx-widget-header div {
  margin: 0 !important;
  text-align: center !important;
}

.MuiDialog-root .MuiGrid-container {
  padding: 10px 20px;
}
.MuiDialog-root .input_record {
  padding: 10px 20px;
}
.MuiDialog-root .input_record table table {
  background-color: #f1f2f5;
  border-radius: 4px;
  padding: 5px;
}
.MuiDialog-root .input_record table table th {
  color: #333;
  font-weight: 700;
}
.MuiDialog-root .input_record table table th > legend::before {
  position: absolute;
  left: auto;
  right: 0;
  top: 2px;
  width: 1px;
  height: 20px;
  background-color: #ccc;
}

/* 로그인 오류팝업*/
.dialog-message-content.MuiDialog-root .MuiDialog-paper {
  background-color: var(--tab-content-bg);
  width: 360px;
  padding: 0 0 52px;
}
.dialog-message-content.MuiDialog-root .MuiDialogTitle-root {
  padding: 10px 20px 5px;
}
.dialog-message-content.MuiDialog-root .MuiTypography-h6 {
  font-size: 15px;
}
.dialog-message-content.MuiDialog-root .MuiDialogContent-root {
  padding: 10px 20px 0;
}

/* alert */
.MuiPaper-root .grid-container {
  padding: 10px 20px;
}
.MuiPaper-root .MuiDialogContent-root,
.MuiPaper-root .table_red .grid-container,
.MuiPaper-root .table_org .grid-container {
  /*padding: 10px 20px;*/
  padding: 0;
}
.MuiPaper-root .MuiDialogContent-root > .jqx-splitter:first-child {
  width: calc(100% - 2px) !important;
  border-radius: 0;
}
.MuiPaper-root .MuiDialogContentText-root {
  margin-bottom: 0;
}
.MuiPaper-root .MuiAlert-root {
  padding: 0;
  background-color: unset;
}
.MuiPaper-root .MuiAlert-icon {
  padding: 0;
}
.MuiPaper-root .MuiAlert-icon .MuiSvgIcon-root {
  width: 37px;
  height: 37px;
  border-radius: 50px;
}
.MuiAlert-standardError .MuiAlert-icon .MuiSvgIcon-root {
  background: #de071a url(/src/images/alert_error.png) no-repeat center;
}
.MuiAlert-standardWarning .MuiAlert-icon .MuiSvgIcon-root {
  background: #ffa921 url(/src/images/alert_warn.png) no-repeat center;
}
.MuiAlert-standardInfo .MuiAlert-icon .MuiSvgIcon-root {
  background: #418cff url(/src/images/alert_info.png) no-repeat center;
}
.MuiAlert-standardSuccess .MuiAlert-icon .MuiSvgIcon-root {
  background: #00c73c url(/src/images/alert_success.png) no-repeat center;
}
.MuiPaper-root .MuiAlert-icon .MuiSvgIcon-root path {
  display: none;
}
.MuiPaper-root .MuiAlert-message {
  padding: 0;
  font-size: 14px;
  color: #666;
}
.MuiPaper-root .MuiAlert-message .MuiTypography-body1 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
  margin-bottom: 0;
}

.MuiPaper-root .MuiDialogActions-root {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
}

/* 대화상자 */
.MuiPaper-root .MuiTypography-body1 {
  font-size: 14px;
  color: #666;
  font-weight: 400;
}

.iso-confirm-dialog-body {
  font-size: 14px;
  color: #666;
  font-weight: 400;
  padding: 8px 20px 45px;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.MuiPaper-root .MuiDialogActions-spacing > :first-child {
  background-color: #de071a;
}
.MuiPaper-root .MuiDialogActions-spacing > :not(:first-child),
.MuiPaper-root .MuiDialogActions-spacing > :last-child {
  background-color: #666;
  margin-left: 0;
}

/* splitter */
/*
.jqx-splitter::before{
    display:block;
    content:'';
    position:absolute;
    top:0;
    left:0;
    width: calc(100% + 2px);
    height: 2px;
    background-color: #de071a;
    z-index: 50;
}*/
.jqx-splitter .jqx-widget-content {
  border: 0;
}
.jqx-splitter .layout .jqx-grid {
  border: 1px solid #ccc;
}
.jqx-splitter {
  border: 1px solid #ccc;
  border-radius: 4px;
}
.splitter_record {
  background-color: #f1f2f5;
  padding-bottom: 15px;
}
.splitter_record .input_record {
  padding: 10px 0 10px 15px;
}
.splitter_record .btn_wrap:first-child {
  padding: 10px 15px;
}
.splitter_record .input_record .jqx-widget-content {
  background-color: unset;
}

.jqx-splitter-panel .input_record {
  padding: 10px 0 10px 15px;
}

.jqx-fill-state-normal {
  /*border-color: #ccc;
  background: #e5e5e5;*/
}
/* jqxTree resize by jqxSplitter */
.jqx-tree-dropdown-root {
  min-width: 300px !important;
  width: 100% !important;
}

/* Files Upload */
.filedrop-container {
  display: block;
  margin: 0;
  width: 100%;
  height: 80px;
  background: #fff;
  border: 1px dashed #999;
  border-radius: 4px;
  overflow-y: scroll;
}
.filedrop-container .drop-message {
  display: block;
  padding: 10px;
  font-size: 13px;
  color: #828282;
  border-bottom: 1px solid #ccc;
}
.filedrop-container .upload-icon {
  display: inline-block;
  width: 21px;
  height: 22px;
  background: url(../images/file.png) no-repeat center;
  display: inline-block;
  vertical-align: top;
  margin-top: -2px;
  margin-right: 7px;
}
.file-display-container .file-name {
  color: #333;
  margin-left: 10px;
}
.file-display-container .file-size {
  font-weight: 400;
}
.file-display-container .file-remove {
  width: 16px;
  height: 16px;
  background: url(../images/close_all.gif) no-repeat center;
  margin-left: 10px;
  text-indent: -99999px;
  overflow: hidden;
}

.fileupload-icon {
  width: 50px;
  height: 50px;
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}
.filedrop-message {
  width: 100%;
  text-align: center;
  color: #4aa1f3;
  font-family: Arial;
  font-size: 20px;
}
.file-upload-container {
  position: relative;
  font-size: 12px;
}
.file-display-container {
  /* position: fixed; */
  width: 100%;
  border: /*groove*/ 1px solid #999;
}
.file-display-container-fixed {
  position: fixed;
  width: 100%;
}
.file-status-bar {
  width: 100%;
  vertical-align: top;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  line-height: 18px;
  height: 18px;
}
.file-status-bar > div {
  overflow: hidden;
}
.file-type {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 5px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}
.file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #4aa1f3;
}
.file-error {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #9aa9bb;
}
.file-error-message {
  color: #de071a;
}
.file-type-logo {
  width: 50px;
  height: 50px;
  /* background: url(../images/generic.png) no-repeat center center;  */
  background-size: 100%;
  position: absolute;
}
.file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}
.file-remove {
  display: inline-block;
  vertical-align: middle;
  line-height: 15px;
  cursor: pointer;
  color: #de071a;
  margin-left: 50px;
}

/* 검색 분할 */
.MuiGrid-item {
  position: relative;
}

.search-part-2 > div {
  display: inline-block;
  width: 25%;
  margin-right: 10px;
}
.search-part-3 > div {
  display: inline-block;
  width: 25%;
  margin-right: 10px;
}
.search-part-4 > div {
  display: inline-block;
  width: 25%;
  margin-right: 10px;
}

/* 여러 Form */
.MuiFormGroup-root {
  display: inline-block !important;
}

/* pannel title */
.pannel-title {
  padding: 0 5px;
  /*background-color: #fdf2f3;*/
  background-color: #e8f7ff;
  border-bottom: 1px solid #999;
  font-size: 14px;
  /*color:#de071a;*/
  color: #096bef;
  font-weight: 700;
  text-align: center;
  line-height: 23px;
}
.table_org .pannel-title {
  background-color: #fff8ed;
  color: #ff9900;
}
.table_grn .pannel-title {
  background-color: #e5f9f7;
  color: #2cab9f;
}
.table_red .pannel-title {
  background-color: #fff5f5;
  color: #de071a;
}

/* calendar */
.calendar-table {
  width: 100% !important;
  margin: 0 !important;
  border-collapse: collapse;
}
.calendar-table th {
  background-color: #fff8ed;
  border: 1px solid #ddd;
}
.calendar-table td {
  padding: 5px 8px;
  vertical-align: top;
  background-color: #fff;
  border: 1px solid #ddd;
}

/* 검색위치의 목록 */
.view_list {
  /* padding: 3px 3px; */
  border-bottom: 1px solid #ddd;
  background-color: #fff8ed;
}
.view_list table {
  border-radius: 4px;
}

.view_list table th {
  font-size: 12px;
  color: #ff9900;
  font-weight: 400;
  text-align: left;
  padding: 3px 5px 0px 5px;
  border-left: 1px dashed #ddd;
}
.view_list table td {
  color: #333;
  font-weight: 700;
  text-align: left;
  padding: 3px 5px 0px 5px;
  border-left: 1px dashed #ddd;
}
.view_list table th:first-child,
.view_list table td:first-child {
  border-left: 0;
}

table.type02 th > legend {
  color: inherit;
}

table.type02.calendar-table th {
  padding: 5px 10px;
}

.calendar-table-row {
  height: 100px;
}
.calendar-table-cell {
  height: 120px;
  overflow-y: auto;
}
.calendar-table-cell .active {
  background-color: #0080c8;
}
.calendar-table-day-0 .calendar-table-date {
  color: #de071a;
}
.calendar-table-day-6 .calendar-table-date {
  color: #777;
}

.calendar-table-week {
  font-size: 13px;
  color: #666;
}
.calendar-table-date {
  font-size: 13px;
  color: #333;
  font-weight: 700;
}
.calendar-table-cell-data {
  padding: 2px 8px;
  font-size: 14px;
  color: #2cab9f;
  font-weight: 400;
  background-color: #e5f9f7;
  border-radius: 4px;
  margin: 3px -2px 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

/* 팝업 */
.MuiDialog-paper {
  min-wdiht: 300px;
}
.MuiDialog-paper .type02 {
  border-width: 1px 0;
}
.MuiDialog-paper .type02 th,
.MuiDialog-paper .type02 td {
  border: 0;
}
.MuiDialog-paper .type02 td .MuiButton-root {
  background-color: #ff9900;
  color: #fff;
}
.MuiDialog-paper .type02 td .MuiButton-root {
  background-color: #d95700;
}
.MuiDialog-paper .type02 td .jqx-fill-state-normal {
}

/* bg */
.bg_gray {
  background-color: #f1f2f5;
}
.bg_org {
  background-color: #fff8ed;
}
.bg_red {
  background-color: #fff5f5;
}
.bg_blue {
  background-color: #e8f7ff;
}
.bg_grn {
  background-color: #e5f9f7;
}

/* no_line */
.no_line .input_search {
  border: 0;
}

.iso-grid-cell-middle-align {
  padding: 0;
  text-align: center;
  vertical-align: middle;
}
.iso-grid-cell-middle-align input,
.iso-grid-cell-middle-align input[type='button'].jqx-fill-state-normal {
  position: relative !important;
  top: auto !important;
  left: auto !important;
  margin-top: 6px !important;
}
.iso-grid-cell-middle-align input[type='button'].jqx-fill-state-hover,
.iso-grid-cell-middle-align input[type='button'].jqx-fill-state-pressed {
  margin: 6px 2px 0 !important;
}

.div-circle-container {
  text-align: center;
  padding-top: 7px;
}
.div-circle {
  height: 14px;
  width: 14px;
  margin-top: 1px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 20%);
}
.bgred {
  background-color: #de071a;
  border: 1px solid #b20615;
}
.bgblue {
  background-color: #2cab9f;
  border: 1px solid #23897f;
}
.bgyellow {
  background-color: #f3eb00;
  border: 1px solid #c2bc00;
}
.bggray {
  background-color: #999;
  border: 1px solid #7a7a7a;
}
.bgwhite {
  background-color: #fff;
  border: 1px solid #ddd;
}

.modal-ok-close .resizable > div {
  height: 100%;
}
.modal-ok-close .resizable > div > .dialog-content-body {
  height: calc(100% - 90px);
}
.modal-ok-close .resizable > div > .dialog-content-body > div {
  height: 100%;
}

.e3-table-grid {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
  background-color: white !important;
  border:1px solid #afb0b7;
  border-radius:3px;
}

.e3-table-grid .MuiTableHead-root{
  position: sticky;
  top: 0;
  left: 0;
  border-bottom:1px solid #c5c5c5;
}

.e3-table-grid .MuiTableContainer-root {
  min-height: 100px;
  overflow: auto;
  height: 100%;
  /*
  min-height: 640px !important;
  max-height: 640px !important;
  */
}

.e3-table-grid .MuiTable-root {
  width: 100% !important;
  padding: 0px !important;
  /* border: 1px solid #444444 !important; */
  border-collapse: collapse !important;
}

.e3-table-grid .MuiTableCell-head {
  border-bottom: 1px solid #afb0b7 !important;
  border-right: 1px dashed #dfe3e6 !important;
  background-color: #f0f1f2 !important;
  font-weight: bold;
}

.e3-table-grid .MuiTableCell-body {
  border-bottom: 1px solid #d2d7dc !important;
  border-right: 1px dashed #dfe3e6 !important;
}

.e3-table-grid .MuiTableCell-body:hover{
  background-color: #f1f2f5!important;
}
.e3-table-grid .MuiTableCell-body[isselected=true]{
  background-color: #fce6e8;
}

.e3-table-grid .MuiTableCell-head.MuiTableCell-sizeSmall{
  padding: 0px;
  line-height: 23px;
}

.e3-table-grid .MuiTableCell-body.e3-table-grid-subtotal-row{
  font-weight: bold;
  background-color: #f0f1f2;
}

.e3-table-grid .MuiTableCell-body.e3-table-grid-total-row{
  color:#d50e16;
  font-weight: bold;
  background-color: #f0e9ea;
}

.main-body-result-area{
  /*height: 100% !important;*/
}
.main-body-result-area .main-body-result-box{
  max-height:calc(100% - 4px) !important;
}