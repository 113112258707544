@charset "utf-8";

@import url(font.css);

/*  loading */
.load_wrap{
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,0.1);
	z-index: 99999;
	display: flex;
	align-items: center;
    justify-content: center;
}
.load_wrap img{
	width: 46px;
	height: 46px;
}

.container{
    width: 500px;
    margin: 0 auto;
}

.tab_header{
    position: relative;
    width: 100%;
    height: 32px;
    padding: 0;
    background: var(--tab-bg);
    border: 0;
    border-bottom: 1px solid var(--tab-border-color);
    z-index: 5;
}

.tab_header .card-body{
    width: calc(100% - 60px);
    height: 32px;
    padding: 0;
    overflow: hidden;
}

.tab_nav{
    position: absolute;
    top: 0;
    right: 0;
}
.tab_nav button{
    display: inline-block;
    width: 25px;
    height: 32px;
    padding: 0;
    background: url(../images/left.gif) no-repeat center;
    text-indent: -99999px;
    border: 0;
    outline: 0;
}
.tab_nav button.next{
    background: url(../images/right.gif) no-repeat center;
}
.tab_nav button.close{
    background: url(../images/close_all.gif) no-repeat center;
}
.tab_nav button:hover{
 cursor: pointer;
}

.tab_wrap{
    position: relative;
    width: calc(100% - 75px);
    height: 32px;
    overflow: hidden;
}

.tab_wrap::before{
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    content: '';
    width: 15px;
    height: 32px;
    background: url(../images/shadow.png) no-repeat right top;
    z-index: 5;
}

ul.tabs{
    width: 2000px;
    list-style: none;
    margin: 0;
    padding: 0;
}
/* scroll width 
ul.tabs::-webkit-scrollbar {
    width: 3px;
  }*/
/* Track 
ul.tabs::-webkit-scrollbar-track {
box-shadow: inset 0 0 0 grey; 
border-radius: 10px;
}*/
/* Handle 
ul.tabs::-webkit-scrollbar-thumb {
background: red; 
border-radius: 10px;
}*/
/* Handle on hover 
ul.tabs::-webkit-scrollbar-thumb:hover {
background: #b30000;
}*/

ul.tabs::after{
    display: block;
    content: '';
    clear: both;
}
ul.tabs li{
    float: left;
    position: relative;
    background: none;
    cursor: pointer;
}
ul.tabs li button.mytab {
    display: inline-block;
    min-width: 120px;
    height: 32px;
    line-height: 30px;
    padding: 0 25px 0 10px;
    background-color: var(--tab-bg);
    border: 1px solid var(--tab-border-color);
    border-top: 0;
    border-left: 0;
    border-radius: 0;
    text-align: left;
    font-size: 13px;
    color:var(--tab-font-color);
    outline: 0;
}

ul.tabs li:first-child:hover button.mytab, ul.tabs li:hover button.mytab{
    background-color: var(--tab-over-bg);
    cursor: pointer;
}

ul.tabs li.active button.mytab {
    background-color: var(--tab-select-bg);
    border-bottom: 0;
    font-weight: bold;
    color:var(--tab-select-font-color);
}

ul.tabs li:first-child button.mytab {
    min-width: 50px;
    width: 50px;
    text-indent:-99999px;
    background: var(--tab-bg) url('../images/home.png') no-repeat center;
    border-left: 1px solid var(--tab-border-color);
}

ul.tabs li:first-child.active button.mytab {
    background: var(--tab-select-bg) url('../images/home_on.png') no-repeat center;
}

ul.tabs li button.mycls{
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 32px;
    background: url(../images/close_off.png) no-repeat center;
    border-style: none;
    background-color: transparent;
    outline: 0;
    text-indent: -99999px;
    overflow: hidden;
}

ul.tabs li.active  button.mycls{
    background-image: url(../images/close_on.png);
}

.tab-content{
    height: 100vh;
    display: none;
    background-color: var(--tab-content-bg);
    border-left: 1px solid #bbb;
}

.tab-content.active{
    display: inherit;
}

.vertical-align{
    display: inline-block;
    vertical-align: middle;
}
/** 
 * ag-grid
*/
.ag-header-cell-label {
    justify-content: center;
 }
 .ag-header-group-cell-label {
    justify-content: center;
}