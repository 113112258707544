/*!
 * TOAST UI Editor : Table Merged Cell Plugin
 * @version 3.0.1 | Wed Oct 27 2021
 * @author NHN FE Development Lab <dl_javascript@nhn.com>
 * @license MIT
 */
.toastui-editor-context-menu .menu-item .merge-cells::before {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGcgZmlsbD0iIzQzNDM0MyIgc3Ryb2tlPSIjNDM0MzQzIj4NCgk8cGF0aCBkPSJNMjM2LjcsMjUzLjdsLTk3LjYtNzcuMmMtMS45LTEuNi00LjctMC4xLTQuNywyLjNWMjM0SDQ0VjQ0aDEzMS45djgyLjdjMCwxLjQsMS4yLDIuNiwyLjYsMi42aDM4LjhjMS40LDAsMi42LTEuMiwyLjYtMi42DQoJCVYxOC4xYzAtMTAtOC4xLTE4LjEtMTguMS0xOC4xSDE4LjFDOC4xLDAsMCw4LjEsMCwxOC4xdjQ3NS44YzAsMTAsOC4xLDE4LjEsMTguMSwxOC4xaDE4My42YzEwLDAsMTguMS04LjEsMTguMS0xOC4xVjM4NS4zDQoJCWMwLTEuNC0xLjItMi42LTIuNi0yLjZoLTM4LjhjLTEuNCwwLTIuNiwxLjItMi42LDIuNlY0NjhINDRWMjc4aDkwLjV2NTUuMmMwLDIuNSwyLjgsMy45LDQuNywyLjNsOTcuNi03Ny4yDQoJCUMyMzguMywyNTcuMiwyMzguMywyNTQuOCwyMzYuNywyNTMuN3ogTTQ5My45LDBIMzEwLjNjLTEwLDAtMTguMSw4LjEtMTguMSwxOC4xdjEwOC42YzAsMS40LDEuMiwyLjYsMi42LDIuNmgzOC44DQoJCWMxLjQsMCwyLjYtMS4yLDIuNi0yLjZWNDRINDY4VjIzNGgtOTAuNXYtNTUuMmMwLTIuNS0yLjgtMy45LTQuNy0yLjNsLTk3LjYsNzcuMmMtMS41LDEuMi0xLjUsMy40LDAsNC42bDk3LjYsNzcuMw0KCQljMS45LDEuNSw0LjcsMC4xLDQuNy0yLjNWMjc4SDQ2OFY0NjhIMzM2LjJ2LTgyLjdjMC0xLjQtMS4yLTIuNi0yLjYtMi42aC0zOC44Yy0xLjQsMC0yLjYsMS4yLTIuNiwyLjZ2MTA4LjYNCgkJYzAsMTAsOC4xLDE4LjEsMTguMSwxOC4xaDE4My42YzEwLDAsMTguMS04LjEsMTguMS0xOC4xVjE4LjFDNTEyLDguMSw1MDMuOSwwLDQ5My45LDB6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==');
  background-position: 5px 2px;
  background-size: 14px 14px;
}

.toastui-editor-context-menu .menu-item .split-cells::before {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGcgZmlsbD0iIzQzNDM0MyIgc3Ryb2tlPSIjNDM0MzQzIj4NCgk8cGF0aCBkPSJNNTEwLjksMjUzLjhsLTkwLjMtNzEuNGMtMS44LTEuNC00LjQtMC4xLTQuNCwyLjJ2NTEuMWgtODYuMVY1OS44aDEyMnY3Ni42YzAsMS4zLDEuMSwyLjQsMi40LDIuNGgzNS45DQoJCWMxLjMsMCwyLjQtMS4xLDIuNC0yLjRWMzUuOWMwLTkuMy03LjUtMTYuNy0xNi43LTE2LjdIMzA2LjJjLTkuMywwLTE2LjcsNy41LTE2LjcsMTYuN3Y0NDAuMmMwLDkuMyw3LjUsMTYuNywxNi43LDE2LjdoMTY5LjkNCgkJYzkuMywwLDE2LjctNy41LDE2LjctMTYuN1YzNzUuNmMwLTEuMy0xLjEtMi40LTIuNC0yLjRoLTM1LjljLTEuMywwLTIuNCwxLjEtMi40LDIuNHY3Ni42aC0xMjJWMjc2LjNoODYuMXY1MS4xDQoJCWMwLDIuMywyLjYsMy42LDQuNCwyLjJsOTAuMy03MS40QzUxMi40LDI1Ny4xLDUxMi40LDI1NC45LDUxMC45LDI1My44eiBNMjA1LjgsMTkuMUgzNS45Yy05LjMsMC0xNi43LDcuNS0xNi43LDE2Ljd2MTAwLjUNCgkJYzAsMS4zLDEuMSwyLjQsMi40LDIuNGgzNS45YzEuMywwLDIuNC0xLjEsMi40LTIuNFY1OS44aDEyMnYxNzUuOEg5NS43di01MS4xYzAtMi4zLTIuNi0zLjYtNC40LTIuMkwxLDI1My44DQoJCWMtMS40LDEuMS0xLjQsMy4yLDAsNC4ybDkwLjMsNzEuNWMxLjcsMS40LDQuNCwwLjEsNC40LTIuMnYtNTEuMWg4Ni4xdjE3NS44aC0xMjJ2LTc2LjZjMC0xLjMtMS4xLTIuNC0yLjQtMi40SDIxLjUNCgkJYy0xLjMsMC0yLjQsMS4xLTIuNCwyLjR2MTAwLjVjMCw5LjMsNy41LDE2LjcsMTYuNywxNi43aDE2OS45YzkuMywwLDE2LjctNy41LDE2LjctMTYuN1YzNS45QzIyMi41LDI2LjYsMjE1LDE5LjEsMjA1LjgsMTkuMXoiLz4NCjwvZz4NCjwvc3ZnPg0K');
  background-position: 5px 2px;
  background-size: 14px 14px;
}

.toastui-editor-dark .toastui-editor-context-menu .menu-item .merge-cells::before {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGcgZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjNDM0MzQzIj4NCgk8cGF0aCBkPSJNMjM2LjcsMjUzLjdsLTk3LjYtNzcuMmMtMS45LTEuNi00LjctMC4xLTQuNywyLjNWMjM0SDQ0VjQ0aDEzMS45djgyLjdjMCwxLjQsMS4yLDIuNiwyLjYsMi42aDM4LjhjMS40LDAsMi42LTEuMiwyLjYtMi42DQoJCVYxOC4xYzAtMTAtOC4xLTE4LjEtMTguMS0xOC4xSDE4LjFDOC4xLDAsMCw4LjEsMCwxOC4xdjQ3NS44YzAsMTAsOC4xLDE4LjEsMTguMSwxOC4xaDE4My42YzEwLDAsMTguMS04LjEsMTguMS0xOC4xVjM4NS4zDQoJCWMwLTEuNC0xLjItMi42LTIuNi0yLjZoLTM4LjhjLTEuNCwwLTIuNiwxLjItMi42LDIuNlY0NjhINDRWMjc4aDkwLjV2NTUuMmMwLDIuNSwyLjgsMy45LDQuNywyLjNsOTcuNi03Ny4yDQoJCUMyMzguMywyNTcuMiwyMzguMywyNTQuOCwyMzYuNywyNTMuN3ogTTQ5My45LDBIMzEwLjNjLTEwLDAtMTguMSw4LjEtMTguMSwxOC4xdjEwOC42YzAsMS40LDEuMiwyLjYsMi42LDIuNmgzOC44DQoJCWMxLjQsMCwyLjYtMS4yLDIuNi0yLjZWNDRINDY4VjIzNGgtOTAuNXYtNTUuMmMwLTIuNS0yLjgtMy45LTQuNy0yLjNsLTk3LjYsNzcuMmMtMS41LDEuMi0xLjUsMy40LDAsNC42bDk3LjYsNzcuMw0KCQljMS45LDEuNSw0LjcsMC4xLDQuNy0yLjNWMjc4SDQ2OFY0NjhIMzM2LjJ2LTgyLjdjMC0xLjQtMS4yLTIuNi0yLjYtMi42aC0zOC44Yy0xLjQsMC0yLjYsMS4yLTIuNiwyLjZ2MTA4LjYNCgkJYzAsMTAsOC4xLDE4LjEsMTguMSwxOC4xaDE4My42YzEwLDAsMTguMS04LjEsMTguMS0xOC4xVjE4LjFDNTEyLDguMSw1MDMuOSwwLDQ5My45LDB6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==');
  background-position: 5px 2px;
}

.toastui-editor-dark .toastui-editor-context-menu .menu-item .split-cells::before {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGcgZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjNDM0MzQzIj4NCgk8cGF0aCBkPSJNNTEwLjksMjUzLjhsLTkwLjMtNzEuNGMtMS44LTEuNC00LjQtMC4xLTQuNCwyLjJ2NTEuMWgtODYuMVY1OS44aDEyMnY3Ni42YzAsMS4zLDEuMSwyLjQsMi40LDIuNGgzNS45DQoJCWMxLjMsMCwyLjQtMS4xLDIuNC0yLjRWMzUuOWMwLTkuMy03LjUtMTYuNy0xNi43LTE2LjdIMzA2LjJjLTkuMywwLTE2LjcsNy41LTE2LjcsMTYuN3Y0NDAuMmMwLDkuMyw3LjUsMTYuNywxNi43LDE2LjdoMTY5LjkNCgkJYzkuMywwLDE2LjctNy41LDE2LjctMTYuN1YzNzUuNmMwLTEuMy0xLjEtMi40LTIuNC0yLjRoLTM1LjljLTEuMywwLTIuNCwxLjEtMi40LDIuNHY3Ni42aC0xMjJWMjc2LjNoODYuMXY1MS4xDQoJCWMwLDIuMywyLjYsMy42LDQuNCwyLjJsOTAuMy03MS40QzUxMi40LDI1Ny4xLDUxMi40LDI1NC45LDUxMC45LDI1My44eiBNMjA1LjgsMTkuMUgzNS45Yy05LjMsMC0xNi43LDcuNS0xNi43LDE2Ljd2MTAwLjUNCgkJYzAsMS4zLDEuMSwyLjQsMi40LDIuNGgzNS45YzEuMywwLDIuNC0xLjEsMi40LTIuNFY1OS44aDEyMnYxNzUuOEg5NS43di01MS4xYzAtMi4zLTIuNi0zLjYtNC40LTIuMkwxLDI1My44DQoJCWMtMS40LDEuMS0xLjQsMy4yLDAsNC4ybDkwLjMsNzEuNWMxLjcsMS40LDQuNCwwLjEsNC40LTIuMnYtNTEuMWg4Ni4xdjE3NS44aC0xMjJ2LTc2LjZjMC0xLjMtMS4xLTIuNC0yLjQtMi40SDIxLjUNCgkJYy0xLjMsMC0yLjQsMS4xLTIuNCwyLjR2MTAwLjVjMCw5LjMsNy41LDE2LjcsMTYuNywxNi43aDE2OS45YzkuMywwLDE2LjctNy41LDE2LjctMTYuN1YzNS45QzIyMi41LDI2LjYsMjE1LDE5LjEsMjA1LjgsMTkuMXoiLz4NCjwvZz4NCjwvc3ZnPg0K');
  background-position: 5px 2px;
}
