:root {
  /* header */
  --header-height: 42px;
  --header-bg: #444444;
  --header-border-color: #383a4a;
  --gnb-font-color: #fff;
  --gnb-over-font-color: #ed1c24;
  --login-info-font-color: #fff;
  /* side-menu */
  --side-menu-bg: #383a49;
  --side-menu-border-bg: #383a49;
  --side-tab-bg: #23232d;
  --side-tab-border-color: #23232d;
  --side-menu-list-bg: #383a49;
  --side-menu-list-over-bg: #ed1b24;
  --side-menu-list-select-bg: #ed1b24;
  --side-menu-list-border-color: #383a49;
  --side-menu-list-over-border-color: #ed1b24;
  --side-menu-list-select-border-color: #ed1b24;
  --side-menu-list-font-color: #fff;
  --side-menu-list-over-font-color: #ed1b24;
  --side-menu-list-select-font-color: #fff;
  --side-hide-font-color: #797a82;
  --side-title-bg: #383a49;
  --side-title-font-color: #fff;
  --side-title-border-color: #383a49;
  /* tab */
  --tab-bg: #c8cccf;
  --tab-over-bg: #f0f1f2;
  --tab-select-bg: #f0f1f2;
  --tab-border-color: #afb0b7;
  --tab-font-color: #686a74;
  --tab-over-font-color: #686a74;
  --tab-select-font-color: #ed1b24;
  --tab-content-bg: #f0f1f2;
  /* title */
  --title-border-color: #f0f1f2;
  /* search */
  --search-bg: #f0f1f2 url('../images/search_bg.png') repeat-x left bottom;
  --search-border-color: #ed1c24;
  --search-button-bg: #ed1c24;
  --search-button-border-color: #d50e16;
  --search-button-font-color: #fff;
  /* result */
  --result-outline: #afb0b7;
  --result-border-h: 1px solid #d2d7dc;
  --result-border-v: 1px dashed #dfe3e6;
  --result-cell-pinned-bg: #fde8e9;
  --result-cell-pinned-font-color: #ed1c24;
  /* record */
  --record-bg: #fff;
  --label-required-font-color: #333;
  /* button */
  --default-button-bg: #64686f;
  --default-button-border-color: #595959;
  --default-button-font-color: #fff;
  --default-button-over-bg: #41464e;
  /* Dialog */
  --dialog-header-bg: #f1f2f5;
  --dialog-header-border-color: #ccc;
}

.jqx-widget-content {
  font-family: AppleSDGothicNeo-Regular, 'Nanum Barun Gothic', 'Malgun Gothic', '맑은 고딕', dotum, '돋움', sans-serif;
  font-size: 14px;
  border-color: #afb0b7 !important;
}

.trigger {
  font-size: 18px;
  line-height: 42px !important;
  /* padding: 0 24px; */
  cursor: pointer;
  transition: color 0.3s;
  float: left;
}
/* .trigger:hover {
  color: #1890ff;
} */

.site-layout-sub-header-background {
  background: #fff;
}
.site-layout .site-layout-background {
  background: #fff;
}
[data-theme='dark'] .site-layout-sub-header-background {
  background: #141414;
}
.form-item {
  margin-bottom: 0px !important;
}
.main-top {
  height: 42px !important;
}
.layout {
  background-color: var(--tab-content-bg);
}
.layout-header {
  height: var(--header-height) !important;
  line-height: var(--header-height) !important;
}
.main-bottom {
  height: 32px !important;
  background-color: #001529;
  color: #fff;
}
.copyright {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #bbb;
}
.layout-footer {
  padding: 5px 50px !important;
}
.main-body {
  display: flex;
  /*min-height: -webkit-calc(100vh - var(--header-height)); */ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  /*min-height: -moz-calc(100vh - var(--header-height));*/ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  min-height: calc(100vh - var(--header-height));
  /*max-height: -webkit-calc(100vh - var(--header-height));*/ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  /*max-height: -moz-calc(100vh - var(--header-height));*/ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  max-height: calc(100vh - var(--header-height));
}

.side-tab::after {
  display: block;
  content: '';
  clear: both;
}
.side-tab {
  padding: 0;
}
.side-tab li {
  float: left;
  width: 33.33333%;
  height: 32px;
  line-height: 30px;
  border-left: 1px solid var(--side-tab-border-color);
  border-bottom: 1px solid var(--side-tab-border-color);
  text-indent: -99999px;
  overflow: hidden;
  background: var(--side-tab-bg) url('../images/like.png') no-repeat center;
}
.side-tab li:first-child {
  border-left: 0;
}
.side-tab li:nth-child(2) {
  background-image: url('../images/menu.png');
}
.side-tab li:nth-child(3) {
  background-image: url('../images/search.png');
}
.side-tab li:hover {
  background-color: var(--side-menu-bg);
  cursor: pointer;
}
.side-tab li.active {
  background: var(--side-menu-bg) url('../images/like_on.png') no-repeat center;
  border-bottom: 1px solid var(--side-menu-border-color);
}
.side-tab li:nth-child(2).active {
  background-image: url('../images/menu_on.png');
}
.side-tab li:nth-child(3).active {
  background-image: url('../images/search_on.png');
}

.side-menu {
  flex: 0 0 200px;
  max-width: 200px !important;
  /*min-height: -webkit-calc(100vh - var(--header-height)); */ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  /*min-height: -moz-calc(100vh - var(--header-height)); */ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  min-height: calc(100vh - var(--header-height));
  /*max-height: -webkit-calc(100vh - var(--header-height)); */ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  /*max-height: -moz-calc(100vh - var(--header-height)); */ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  max-height: calc(100vh - var(--header-height));

  position: relative;
  background: var(--side-menu-bg);
  padding-bottom: 45px;
}
.side-menu-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.side-menu-children .MuiList-root::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.side-menu-children .MuiList-root::-webkit-scrollbar-thumb {
  background: var(--side-tab-border-color);
}
.side-menu-children .MuiList-root::-webkit-scrollbar-track {
  background: var(--side-menu-bg);
}
.side-menu-children .MuiList-root::-webkit-scrollbar-corner {
  background: var(--side-menu-bg);
}

.side-menu h2 {
  height: 48px;
  line-height: 47px;
  padding: 0 17px;
  background-color: var(--side-title-bg);
  font-size: 17px;
  font-weight: bold;
  color: var(--side-title-font-color);
  border-bottom: 1px solid var(--side-title-border-color);
  margin-bottom: 0;
}
.side-menu-bar {
  display: block;
  overflow: hidden;
  padding: 0 !important;
  max-height: calc(100vh - 185px);
  height: calc(100vh - 185px);
  max-width: 200px;
  overflow: auto;
}
.side-menu-search-bar {
  display: block;
  overflow: hidden;
  padding: 0 !important;
  max-height: calc(100vh - 185px);
  height: calc(100vh - 215px);
  overflow: auto;
}
.side-menu-favorit-area {
  display: block;
  overflow: hidden;
  padding: 0 !important;
  max-height: calc(100vh - 185px);
  height: calc(100vh - 185px);
  overflow: auto;
}
.side-menu-1lvl {
  box-sizing: border-box;
  font-size: 14px;
  color: #333;
  font-weight: 700;
  line-height: 36px;
  width: 100%;
}
li.side-menu-1lvl {
  position: relative;
  background-color: var(--side-menu-list-bg);
  border-bottom: 1px solid var(--side-menu-list-border-color);
  height: 42px;
  padding: 0 17px;
  transition: all 0.3s;
  width: 100%;
  color: var(--side-menu-list-font-color);
  white-space: nowrap;
  /* overflow: hidden;
    text-overflow: ellipsis;
    overflow-x: auto; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
li.side-menu-1lvl::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
li.side-menu-1lvl:hover {
  background-color: var(--side-menu-list-bg);
  color: var(--side-menu-list-over-font-color);
  border-color: var(--side-menu-list-over-border-color);
  cursor: pointer;
}
li.side-menu-1lvl span {
  padding-right: 10px;
  white-space: nowrap;
}

.MuiListItem-root {
}
.MuiListItem-root .MuiSvgIcon-root {
  background: url('../images/sider_down.gif') no-repeat center;
}
.MuiListItem-root.Mui-selected .MuiSvgIcon-root {
  background: url('../images/sider_up.gif') no-repeat center;
}
.MuiListItem-root .MuiSvgIcon-root path {
  display: none;
}

li.side-menu-1lvl.Mui-selected {
  background-color: var(--side-menu-list-select-bg);
  color: var(--side-menu-list-select-font-color);
  border-color: var(--side-menu-list-select-border-color);
}
.side-menu-bar .MuiCollapse-container {
  border-bottom: 1px solid #c9c9ca;
}
.side-menu-bar .MuiCollapse-wrapper li {
  position: relative;
  background-color: #23232d !important;
  padding-left: 15px;
  font-size: 13px;
  color: #ccc;
  font-weight: 400;
  height: 33px;
  line-height: 33px;
  border-bottom: 0;
}
.side-menu-bar .MuiCollapse-wrapper li:hover,
.side-menu-bar .MuiCollapse-wrapper li.Mui-selected {
  color: #de071a !important;
}
.side-menu-bar .MuiCollapse-wrapper li:hover::before,
.side-menu-bar .MuiCollapse-wrapper li.Mui-selected::before {
  position: absolute;
  top: -1px;
  left: 0;
  display: block;
  content: '';
  width: 5px;
  height: 36px;
  background: url('../images/sider_menu_bullet.gif') no-repeat 0 47%;
}

li.side-menu-1lvl svg {
  position: absolute;
  top: 20%;
  right: 16px;
}
.side-menu-bar li.Mui-selected::after {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
  -webkit-transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.side-sub-menu-1lvl {
  font-size: 14px;
  color: #fff;
  line-height: 30px;
}

.side_option {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  padding: 0 15px;
  line-height: 19px;
}
.side_option label {
  font-size: 12px;
  color: #777;
}
.side_option input {
  position: absolute;
  top: 0;
  right: 15px;
  width: 38px;
  height: 19px;
  cursor: pointer;
  opacity: 0;
  z-index: 5;
}
.side_option input ~ i {
  position: absolute;
  top: 0;
  right: 15px;
  width: 38px;
  height: 19px;
  background: url('../images/lnb_hide.png') no-repeat;
}
.side_option input:checked ~ i {
  background: url('../images/lnb_hide_on.png') no-repeat;
}

.MuiCollapse-container {
  background: #dadbdf;
  padding-left: 15px;
}
.MuiCollapse-wrapper {
  background: #eaebef;
  border-left: 1px solid #ed1c24;
  color: #666;
}

.layout-content {
  flex: auto;
  /*min-height: -webkit-calc(100vh - var(--header-height)); */ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  /*min-height: -moz-calc(100vh - var(--header-height)); */ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  min-height: calc(100vh - var(--header-height));
  /*max-height: -webkit-calc(100vh - var(--header-height)); */ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  /*max-height: -moz-calc(100vh - var(--header-height)); */ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  max-height: calc(100vh - var(--header-height));
  overflow-x: hidden;
  overflow-y: hidden;

  position: relative;
  background: #cbccd0;
}

.main-body-content {
  /*min-height: -webkit-calc(100vh - 144x); */ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  /*min-height: -moz-calc(100vh - 144px); */ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  min-height: calc(100vh - 144px);
  /*max-height: -webkit-calc(100vh - 144px); */ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  /*max-height: -moz-calc(100vh - 144px); */ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  max-height: calc(100vh - 144px);
  min-width: 1000px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.main-menu-tabs {
  min-height: 32px;
  max-height: 32px;

  background-color: #fff;
  border-bottom: solid 1px darkgrey;
}
.main-body-title-area {
  position: relative;
  width: 100%;
  height: 48px;
  line-height: 47px;
  background-color: var(--tab-content-bg);
  border-bottom: 1px solid var(--title-border-color);
  padding: 0 0 0 17px;
}
.main-body-title-area ul.title {
  display: block;
  content: '';
  clear: both;
}
.main-body-title-area .title li {
  float: left;
  margin-right: 7px;
  font-size: 17px;
  color: #000;
  font-weight: 700;
  /*background: url('../images/tit_arrow.png') no-repeat right center;
    padding-right: 13px;*/
}
.main-body-title-area .title li:nth-child(1) {
  display: none;
}
.main-body-title-area .title li:last-child {
  display: inline-block;
  background-color: #fff;
  border-radius: 100px;
  padding: 0 7px;
  font-size: 14px;
  color: #666;
  line-height: 1.5;
  margin-top: 13px;
}
.title-btn {
  position: absolute;
  top: 8px;
  right: 10px;
}
.title-btn li {
  float: left;
  background: none;
  margin-right: 0;
}
.title-btn li a {
  display: block;
  width: 30px;
  height: 30px;
  text-indent: -99999px;
  background: url('../images/t_refresh.png') no-repeat center;
}

.title-btn li.like a,
.title-btn li a.like {
  background: url('../images/t_like.png') no-repeat center;
}
.title-btn li.like a:hover,
.title-btn li a.like:hover,
.title-btn li.like a.selected,
.title-btn li.like.selected a {
  background: url('../images/t_like_on.png') no-repeat center;
}

.main-body-title-box {
}
.breadcrumbs-title {
}
.breadcrumbs-title-item {
}
.breadcrumbs-title-item + .breadcrumbs-title-item::before {
  content: '>';
}
.main-body-search-area {
  width: 100%;
  /*background-color: #fff;*/
}
.main-body-search-box {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.main-body-result-area {
  display: flex;
  flex-grow: 1;
  /* flex: 1 1 auto; */
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.p-0 .main-body-result-area {
  padding: 0;
}
.p-0 .jqx-tabs {
  border: 0;
}
.main-body-result-box {
  position: relative;
  display: inline-block;
  width: 100%;
  /* min-height: -webkit-calc(100vh - 266px);
    min-height: -moz-calc(100vh - 266px);
    min-height: calc(100vh - 266px); */
  /*max-height: -webkit-calc(100vh - 144px); */ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  /*max-height: -moz-calc(100vh - 144px); */ /*빌드 오류로 인해 -webkit-calc, -moz-calc 주석처리*/
  max-height: calc(100vh - 144px);
  min-width: 1000px;
  padding: 18px 18px 0;
  border-radius: 3px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  margin: 2px;
}
.main-body-form-area {
  /* display: flex; */
  /* flex-grow: 1; */
  /* flex: 1, 1, auto; */
  width: 100%;
  height: 25%;
  padding: 12px 18px 10px;
  border-top: 1px solid #c9c9ca;
  background-color: #f1f2f5;
}
.main-body-form-box {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 10px 5px 10px 20px;
  /* background-color: var(--record-bg); */
  border-radius: 3px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.breadcrumb span,
.breadcrumb a,
.layout-header > span {
  color: #fff;
}
.menu-inline-collapsed {
  width: 40px;
}
.fill-grid {
  height: 100%;
}
.rdg {
  --font-size: 12px;
}

.signForm button {
  padding: 0px 24px;
  height: 58px;
  line-height: 58px;
  border: 0;
  background-color: #3182f6;
  color: #fff;
  font-size: 18px;
  border-radius: 6px;
  transition: background-color 0.2s ease;
}
.signForm button + button {
  margin-left: 8px;
}
.signForm button:hover {
  background-color: #1973f5;
}
.signForm button:active {
  background-color: #4991f7;
}
.signForm input {
  display: block;
  height: 58px;
  line-height: 58px;
  background-color: #fafafc;
  border: 0;
  border-bottom: 2px solid lightgrey;
  padding: 0 16px;
  margin-bottom: 8px;
  font-size: 18px;
  outline: 0;
  transition: border-color 0.2s ease;
}
.signForm input:focus {
  border-color: #3182f6;
}
.signForm input::placeholder {
  font-size: 18px;
}

/* 상단 */
.layout-header {
  position: relative;
  height: 42px;
  padding: 0px !important;
  background-color: var(--header-bg);
  /* border-bottom: 1px solid var(--header-border-color); */
}
.logo {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 250px;
  float: left;
  height: 100%;
  background: #de071a;
}
.logo a {
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  color: #fff;
  /* line-height: 44px; */
  height: var(--header-height);
  line-height: calc(var(--header-height) - 6px);
}
.logo a > img {
  vertical-align: middle;
}
.logo a > .text {
  font-size: 15px;
  /* color: #333; */
  font-weight: bold;
  letter-spacing: -0.75px;
  margin-left: 10px;
}
.icon-home {
  width: 19px;
  height: 17px;
  background: url('../images/home.png') no-repeat center;
  text-indent: -99999px;
  margin-right: 10px;
}
.icon-menu-fold {
  width: 42px;
  height: 100%;
  background: url('../images/b_left_white.gif') no-repeat 15px 50%;
  text-indent: -99999px;
  overflow: hidden;
  padding: 0 !important;
}
.icon-menu-unfold {
  width: 42px;
  height: 100%;
  background: url('../images/b_right_white.gif') no-repeat 15px 50%;
  text-indent: -99999px;
}
/* 상단 메뉴 */
.layout-header .menu_wrap {
  height: 100%;
  /* text-align: right; */
  padding-right: 220px;
}
.layout-header .menu {
  display: inline-block;
  height: 100%;
}
.layout-header .menu-item {
  float: left;
  height: var(--header-height);
  line-height: calc(var(--header-height) - 6px);
  margin: 0 20px;
  font-size: 15px;
  font-weight: bold;
  color: var(--gnb-font-color);
  border-top: 3px solid var(--header-bg);
  transition: all 0.3s;
}
.layout-header .menu-item:first-child {
  /*margin-left:30px;*/
}
.menu .menu-item:hover,
.menu .menu-item-active,
.menu-submenu-popup .menu-item-active,
.menu .menu-item-selected,
.menu-submenu-popup .menu-item-selected {
  background: none;
  color: var(--gnb-over-font-color);
  border-top: 3px solid var(--header-bg);
  cursor: pointer;
}

.layout-header .menu {
  height: 100%;
}
.menu.menu-horizontal {
  border-bottom: 0;
}
.menu-horizontal > .menu-item,
.menu-horizontal > .menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}
.menu.menu-horizontal > .menu-item,
.menu.menu-horizontal > .menu-submenu {
  top: 0;
  margin-top: 0;
  border-color: #001529;
  border-bottom: 0;
}
.menu::before {
  display: table;
  content: '';
}
.menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
/* 로그아웃 버튼 */
.b_logout {
  position: absolute;
  top: 0;
  right: 10px;
  height: var(--header-height);
  line-height: calc(var(--header-height) - 6px);
  font-size: 13px;
  color: var(--login-info-font-color);
}
.b_logout span {
  display: inline-block;
  max-width: 150px;
  padding: 0;
  height: var(--header-height);
  line-height: calc(var(--header-height) - 6px);
  /* border-radius: 30px; */
  vertical-align: top;
  /* margin-top: 16px; */
}
.b_logout span:hover {
  /*background-color: #333;*/
  cursor: pointer;
}
.b_logout span:before {
  display: inline-block;
  content: '';
  width: 21px;
  height: 21px;
  background: #fde8e9 url('../images/login_info.png') no-repeat center;
  border-radius: 100px;
  vertical-align: middle;
  margin-right: 3px;
  /* margin-top: 2px; */
}
.b_logout button {
  position: relative;
  width: 28px;
  height: 28px;
  background: url('../images/logout_white.png') no-repeat center;
  border: 0 !important;
  text-indent: -99999px;
  /*overflow: hidden;*/
  outline: 0;
}
.b_logout button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.b_logout button.logout {
  margin-right: 5px;
}
.b_logout button.full_screen {
  background-image: url('../images/full_white.png');
}
.b_logout button.origin_screen {
  background-image: url('../images/origin.png');
}
.b_locale {
  position: absolute;
  top: 0;
  right: 20px;
  height: 100%;
  font-size: 13px;
  color: #333;
}
.select-locale {
  background: none;
  border: 0;
  outline: 0;
  font-size: 13px;
  color: #fff;
  line-height: 30px;
  height: 30px;
  width: 80px;
  overflow: hidden;
}
.select-locale option {
  background: #444;
}

/* 왼쪽 */
.layout-sider .menu,
.layout-sider .menu-sub,
.layout-sider .menu .menu-sub {
  background: transparent;
}

.layout-sider .menu-item {
  border-bottom: 1px solid #484848;
  padding: 0 20px !important;
  margin: 0;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.layout-sider .menu .menu-item:hover,
.layout-sider .menu .menu-item-active,
.layout-sider .menu-submenu-popup .menu-item-active {
  background: none;
  color: #ff9e00;
}
.layout-sider .menu .menu-item-selected,
.layout-sider .menu-submenu-popup .menu-item-selected {
  position: relative;
  background: none;
  color: #ff9e00;
}
.layout-sider .menu .-menu-item-selected::before,
.layout-sider .menu-submenu-popup .menu-item-selected::before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: #ff9e00;
}
.menu-vertical .menu-item:not(:last-child),
.menu-vertical-left .menu-item:not(:last-child),
.menu-vertical-right .menu-item:not(:last-child),
.menu-inline .menu-item:not(:last-child) {
  margin-bottom: 0;
}

.formArea {
  padding: 0;
  margin: 0 auto;
}
ul.formGroup {
  width: 100%;
  flex: 1;
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
}
ul.formGroup li {
  width: 25%;
  display: inline-block;
  list-style-type: none;
  margin: 5px 0;
  text-align: center;
}
label.formLabel {
  display: inline-block;
  width: 28%;
  flex: 1;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  line-height: 24px;
  border: 1px solid #c5c5c5;
  background-color: #e8e8e8;
  color: #000;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: 400;
}
.formInput {
  display: inline-block;
  width: 65%;
  flex: 1;
  line-height: 24px;
  border: 1px solid #c5c5c5;
  border-left: 0px;
}
.formInput[type='text']:focus {
  border: 1px solid #4757e6 !important;
}

.footer {
  display: none;
  position: relative;
  width: 100%;
  height: 22px;
  line-height: 22px;
  padding-left: 15px;
  background: #9e9e9e;
  font-size: 11px;
  color: #fff;
}
.footer copyright {
  position: absolute;
  top: 0;
  left: 15px;
  height: 100%;
  font-style: normal;
}
.footer .version {
  position: absolute;
  top: 0;
  right: 15px;
  height: 100%;
}

/* login */
.MuiContainer-root.login_wrap {
  display: table;
  width: 100%;
  max-width: unset;
  height: 100vh;
  margin: 0;
  padding: 0;
  /* background-color: var(--tab-content-bg); */
  background: url(/src/images/bg.png) no-repeat center;
}
.MuiContainer-root.login_wrap > div {
  display: table-cell;
  width: 100%;
  height: 100vh;
  text-align: center;
  vertical-align: middle;
}
.MuiContainer-root.login_wrap > div > .main-login-car {
  display: inline-block;
  width: 360px;
  background-color: unset;
  box-shadow: unset;
}
.MuiContainer-root.login_wrap .MuiCardContent-root {
  padding: 0;
}

.login_wrap .MuiCardHeader-root {
  padding: 0;
}
.login_wrap h2 {
  /*font-size:38px;color:#000;font-weight:normal;*/
  text-align: left;
  font-size: 22.5px;
  color: #333;
  font-weight: bold;
  letter-spacing: -1px;
  margin-bottom: 25px;
}
.login_wrap h2 .MuiCardMedia-root {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: top;
  margin-right: 7px;
}
.login_wrap .MuiTypography-h5 {
  height: 43px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 22px;
  text-indent: -99999px;
  overflow: hidden;
}
.MuiCardMedia-root {
  width: unset !important;
  background-size: contain !important;
  margin-bottom: 22px;
}

.login-input label {
  display: none;
}
.login-input .MuiFormControl-root {
  width: 100%;
  margin-bottom: 10px;
}
.login-input .MuiInputBase-input {
  box-sizing: border-box;
  height: 46px !important;
  background-color: #fff !important;
  font-size: 14.5px !important;
  color: #333 !important;
  padding: 2px 8px !important;
}
.login-input .MuiInputAdornment-positionEnd {
  margin-left: 0;
}
.login-input .MuiIconButton-root {
  position: absolute;
  top: 9px;
  right: 2px;
  padding: 2px 8px !important;
}

.login-remember label {
  margin: 0;
  padding: 0;
}
.login-remember label .MuiTypography-body1 {
  font-size: 14px;
  color: #333;
}

.btn_login {
  margin-top: 15px !important;
}
.btn_login button {
  height: 50px;
  border-radius: 0 !important;
}
.btn_login button.MuiButton-containedPrimary {
  background-color: #de071a;
}
.btn_login .MuiButton-label {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}
.btn_login button .MuiButton-label .MuiSvgIcon-root,
.btn_login button .MuiButton-label > div {
  display: none;
}
.footer_login {
  text-align: center;
  font-size: 13px;
  color: #999;
  margin-top: 25px;
}

/* home/error */
.home_img,
.error_img {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 144px);
  /* background: url(/src/images/bg.png) no-repeat center/cover; */
  align-items: center;
  justify-content: center;
}
.error_img h4 {
  font-size: 18px;
  color: #de071a;
  font-weight: 600;
}
.error_img h4::before {
  display: block;
  content: '';
  width: 49px;
  height: 49px;
  background: url(/src/images/error.png) no-repeat center;
  border: 1px solid #de071a;
  border-radius: 100px;
  margin: 0 auto 5px;
}
.error_img p {
  font-size: 14px;
  color: #666;
}

/* 메뉴 숨기기 */
.menu_hide {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  padding: 0 30px;
}
.menu_hide h5 {
  display: inline-block;
  font-size: 13px;
  color: var(--side-hide-font-color);
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 30px;
}
.menu_hide .side-menu-auto-hidden .MuiSvgIcon-root {
  width: 38px;
  height: 19px;
  border: 0;
  background: url(/src/images/menu_hide.png) no-repeat center;
}
.menu_hide .side-menu-auto-hidden .Mui-checked .MuiSvgIcon-root {
  background-image: url(/src/images/menu_hide_on.png);
}

/* menu search */
#menulist {
  position: relative;
  width: 200px;
  height: 30px;
  background: var(--side-menu-bg) url(../images/btn_search.png) no-repeat 10px 50% !important;
  border: 0 !important;
  border-bottom: 1px solid #000 !important;
  border-radius: 0;
  padding-left: 30px !important;
  box-sizing: border-box;
}

.inline-block {
  display: inline-block !important;
}
.child-inline-block > div {
  display: inline-block !important;
}
.container-padding {
  padding: 10px 20px;
}
.vertical_top {
  vertical-align: top !important;
}

h3 {
  font-size: 15px;
  color: #333;
  font-weight: 700;
  margin: 20px 0 5px;
}
h3:first-child {
  margin: 0 0 5px;
}
.no-main-body-form-area .main-body-form-area {
  display: none;
}
.container-padding th {
  width: 200px !important;
}
.container-padding td {
  width: auto !important;
}
.label_as_title td th label {
  padding-left: 0;
  color: #333;
}
.label_as_title td th label::before {
  display: none;
}

.legend_title .MuiFormLabel-root {
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: #999;
  font-weight: 600;
  padding-left: 10px;
  line-height: 1.6;
  text-align: left;
}
.legend_title .MuiFormLabel-root::before {
  display: block;
  content: '';
  position: absolute;
  top: 9px;
  left: 0;
  width: 4px;
  height: 4px;
  background-color: #aaa;
}

/* width */
.col-1 {
  width: 4.1666% !important;
}
.col-2 {
  width: 8.3333% !important;
}
.col-3 {
  width: 12.5% !important;
}
.col-4 {
  width: 16.6666% !important;
}
.col-5 {
  width: 20.8333% !important;
}
.col-6 {
  width: 25% !important;
}
.col-7 {
  width: 29.1666% !important;
}
.col-8 {
  width: 33.3333% !important;
}
.col-9 {
  width: 37.5% !important;
}
.col-10 {
  width: 41.6666% !important;
}
.col-11 {
  width: 45.8333% !important;
}
.col-12 {
  width: 50% !important;
}
.col-13 {
  width: 54.1666% !important;
}
.col-14 {
  width: 58.3333% !important;
}
.col-15 {
  width: 62.5% !important;
}
.col-16 {
  width: 66.6666% !important;
}
.col-17 {
  width: 70.8333% !important;
}
.col-18 {
  width: 75% !important;
}
.child-col-18 > div {
  display: inline-block !important;
  width: 75% !important;
}
.col-19 {
  width: 79.1666% !important;
}
.child-col-19 > div {
  display: inline-block !important;
  width: 79.1666% !important;
}
.col-20 {
  width: 83.3333% !important;
}
.child-col-20 > div {
  display: inline-block !important;
  width: 83.3333% !important;
}
.col-21 {
  width: 87.5% !important;
}
.col-22 {
  width: 91.6666% !important;
}
.col-23 {
  width: 95.8333% !important;
}
.col-24 {
  width: 100% !important;
}

.p-0 {
  padding: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pt-3 {
  padding-top: 3px !important;
}
.pl-3 {
  padding-left: 3px !important;
}
.pr-3 {
  padding-right: 3px !important;
}
.p-5 {
  padding: 5px !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pl-7 {
  padding-left: 7px !important;
}
.pr-7 {
  padding-right: 7px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}

.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}

.mt-m20 {
  margin-top: -20px !important;
}

table.type01 {
  border-collapse: collapse;
  text-align: left;
  line-height: 1.5;
  margin: 2px 10px 2px 10px;
}
table.type01 th {
  width: 150px;
  padding: 5px 7px;
  font-weight: bold;
  vertical-align: top;
  border: 1px solid #ccc;
}
table.type01 td {
  width: 350px;
  padding: 5px 7px;
  vertical-align: top;
  border: 1px solid #ccc;
}

table.type02 {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  line-height: 1.5;
  border: 1px solid #ccc;
  width: calc(100% - 30px);
  margin: 10px 15px;
}
table.type02 th {
  width: 150px;
  padding: 5px 10px;
  color: #de071a;
  font-weight: bold;
  text-align: right;
}
table.type02 td {
  padding: 5px 10px;
}

table.type03 {
  border-collapse: collapse;
  text-align: left;
  line-height: 1.5;
  margin: 2px 10px 2px 10px;
}
table.type03 th {
  width: 95px;
  padding: 5px 7px;
  font-weight: bold;
  vertical-align: top;
  border: 1px solid #ccc;
}
table.type03 td {
  /* width: 350px; */
  padding: 5px 7px;
  vertical-align: top;
  border: 1px solid #ccc;
}

/* tooltip */
.tab_nav button,
.title-btn li a,
.b_logout button {
  position: relative;
}
.tab_nav button:hover .tooltip,
.title-btn li a:hover .tooltip,
.b_logout button:hover .tooltip {
  display: inline-block;
  position: absolute;
  top: 32px;
  right: 5px;
  width: max-content;
  height: 23px;
  line-height: 21px;
  padding: 0 7px;
  font-style: normal;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #333;
  border-radius: 4px;
  text-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 2px 0px;
  text-indent: 0;
  z-index: 100;
}

/* footnote */
.footnote {
  display: inline-block;
  font-size: 13px;
  color: #999;
  margin-top: 5px;
}

/* link_wrap */
.link_wrap {
  width: 600px;
  padding: 50px;
  margin: 0 auto;
}
.link_wrap > div:first-child {
  background-color: #fff5f5;
  border: 1px solid #de071a;
  border-bottom: 0;
  padding: 20px 20px 15px;
  text-align: center;
}
.link_wrap > .MuiGrid-root {
  background-color: #fff5f5;
  border: 1px solid #de071a;
  border-top: 0;
  padding: 0 20px 20px;
  text-align: center;
}
.link_wrap .btn_wrap {
  padding: 0;
}
.link_wrap h2 {
  font-size: 16px;
  color: #de071a;
  font-weight: 700;
}

/* Dashboard */
.DashboardLayoutContentBox {
  margin: 0 -18px;
}
.DashboardLayoutContentBox .MuiContainer-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.DashboardLayoutContentBox .MuiPaper-root {
  height: 100%;
  background-color: unset;
  box-shadow: none;
}
.DashboardLayoutContentBox .MuiCardHeader-root {
  padding: 0;
}
.DashboardLayoutContentBox .MuiCardHeader-root .MuiTypography-h5 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 7px;
}
.DashboardLayoutContentBox .MuiDivider-root {
  display: none;
}
.DashboardLayoutContentBox .MuiGrid-container {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  margin: 0 !important;
}
.DashboardLayoutContentBox .MuiGrid-item {
  padding: 10px 18px !important;
}
.DashboardLayoutContentBox > .MuiGrid-item {
  display: flex;
}
.DashboardLayoutContentBox .dashboard-card-body {
  height: calc(100% - 31px);
  background-color: #fff;
  border-top: 1px solid #383a4a;
  border-bottom: 1px solid #dfe3e6;
  padding: 20px 12px 12px !important;
}
.DashboardLayoutContentBox .dashboard-facilitycard-body {
  height: calc(100% - 31px);
  background-color: #fff;
  border-radius: 3px;
  padding: 20px 12px 12px !important;
}
.DashboardLayoutContentBox .BoxBetween {
  margin-bottom: 10px !important;
}
.DashboardLayoutContentBox .BoxBetween .MuiGrid-item {
  padding: 0 !important;
}
.DashboardLayoutContentBox .dashboard-card-bulet {
  display: inline-block;
  padding: 0 5px;
  background-color: var(--default-button-bg);
  border: 1px solid var(--default-button-border-color);
  border-radius: 100px;
  font-size: 13px;
  color: #fff !important;
  margin-bottom: 5px;
}
.DashboardLayoutContentBox .MuiGrid-item:nth-child(2) .dashboard-card-bulet {
  background-color: #fb6c07;
  border: 1px solid #e25e00;
}
.DashboardLayoutContentBox .MuiGrid-item:nth-child(3) .dashboard-card-bulet {
  background-color: var(--search-button-bg);
  border: 1px solid var(--search-button-border-color);
}
.DashboardLayoutContentBox .dashboard-card-value {
  font-size: 38.8px;
  font-weight: bold;
}
.DashboardLayoutContentBox .dashboard-card-unit {
  font-size: 19.5px;
}
.DashboardLayoutContentBox .dashboard-facilitycard-bulet {
  display: inline-block;
  padding: 0 5px;
  background-color: #8c8c8c;
  border: 1px solid #747474;
  border-radius: 100px;
  font-size: 13px;
  color: #fff !important;
  margin-bottom: 5px;
}
.DashboardLayoutContentBox .dashboard-facilitycard-value {
  font-size: 32.5px;
  font-weight: bold;
}
.DashboardLayoutContentBox .dashboard-facilitycard-unit {
  font-size: 19.5px;
}
.DashboardLayoutContentBox .dashboard-facilitycard-work-bar-container {
  background-color: #f0f1f2;
  border-radius: 3px;
  margin-top: 2px;
}
.DashboardLayoutContentBox .dashboard-facilitycard-work-bar {
  margin-bottom: 0 !important;
}
.DashboardLayoutContentBox .dashboard-facilitycard-work-bar > div {
  width: 50%;
}
.DashboardLayoutContentBox .dashboard-facilitycard-work-title {
  font-size: 14.5px;
  line-height: 22px;
}
.DashboardLayoutContentBox .dashboard-facilitycard-work-count {
  font-size: 24px;
  font-weight: bold;
  line-height: 22px;
  text-align: right;
}
.DashboardLayoutContentBox .dashboard-facilitycard-work-unit {
  font-size: 14.5px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-all;
  max-height: 6em;
}
